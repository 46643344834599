<template>
  <teleport to="body">
    <div v-if="keyPad" class="modal" @click.self.prevent="close">
      <div class="keypad">
        <font-awesome-icon icon="fa-circle-xmark" class="exit" @click="close" />
        <div class="keypad-display">
          <h2>Amount: {{ amount }}</h2>
          <font-awesome-icon icon="fa-delete-left" class="exit" @click="clearInput" size="xl" />
        </div>
        <div class="keypad-buttons">
          <button v-for="key in keys" :key="key" @click="addKey(key)">
            {{ key }}
          </button>
          <button @click.stop.prevent="addKey('.')">.</button>
          <button @click.stop.prevent="addKey(0)">0</button>
          <button @click.stop.prevent="enter">Enter</button>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
export default {
  name: "KeyPad",
  props: {
    keyPad: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["close", "enter"],
  data() {
    return {
      keys: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      amount: "",
    };
  },
  methods: {
    addKey(key) {
      this.amount += key;
    },

    clearInput() {
      this.amount = "";
    },

    close() {
      this.$emit("close");
    },

    enter() {
      this.$emit("enter", this.amount);
      this.amount = "";
      this.$emit("close");
    },
  },
  computed: {
    // Define computed properties here
  },
};
</script>

<style lang="scss" scoped>
@import "public/wrapper";
.keypad {
  border-radius: 1rem;
  h2 {
    margin: 1rem;
  }
  .keypad-display {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    box-shadow: var(--box-shadow);
    border-radius: 1rem;
    margin: 1rem;
    background-color: var(--primary-colour);
  }
  .keypad-buttons {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    button {
      padding: 2rem;
      font-size: 2.5rem;
      border: none;
      box-shadow: var(--box-shadow);
      border-radius: 0.5rem;
      background-color: var(--primary-colour);
      color: var(--text-colour);
      cursor: pointer;
      &:active {
        background-color: var(--action-colour);
      }
    }
  }
}
</style>
