<template>
  <teleport to="body">
    <div
      v-if="openModal"
      class="modal"
      @click.self.prevent="
        openModal = !openModal;
        openNotesModal = false;
      ">
      <div class="outer">
        <font-awesome-icon icon="fa-circle-xmark" class="exit bump-right" @click="openModal = !openModal" />
        <form @submit.stop.prevent>
          <div class="details">
            <!-- <h2 class="remaining">
            <span class="label" v-if="remaining >= 0">Balance</span>
            <span class="label" v-if="remaining < 0">Change for Customer</span>
            <span class="value">{{ '$' + remaining.toFixed(2) }}</span>
            </h2> -->
            <ul class="log" v-if="showLog">
              <li v-bind:key="idx" v-for="(payment, idx) in payments">{{ payment.type }} - {{ "$" + payment.value.toFixed(2) }}</li>
            </ul>

            <div class="notes">
              <div>
                <h4 v-if="siteSettings && siteSettings.nameRequired">Name</h4>
                <input v-if="siteSettings && siteSettings.nameRequired" type="text" v-model="name" placeholder="Customer Name..." />
              </div>
              <div v-if="allergens">
                <h4 v-if="siteSettings && siteSettings.notesRequired">Allergens</h4>
                <textarea v-model="notes" placeholder="Add Allergen Notes" />
              </div>
              <div>
                <h4 v-if="siteSettings && siteSettings.notesRequired">Notes</h4>
                <textarea v-if="siteSettings && siteSettings.notesRequired" v-model="notes" placeholder="Order Notes..." />
              </div>
            </div>

            <div>
              <div class="delivery" v-if="siteSettings.delivery_options.length > 1">
                <span v-bind:key="delivery_option" @click="selectDelivery(delivery_option)" v-for="delivery_option in siteSettings.delivery_options">
                  <input type="radio" v-model="delivery" :class="delivery_option" name="option" :value="delivery_option" />{{ delivery_option }}
                </span>
              </div>
            </div>

            <div v-if="discounts.length">
              <h4>Discounts</h4>
              <div class="payments">
                <button class="button is-primary" @click="applyDiscount(discount)" :key="discount" v-for="discount in activeDiscounts()">
                  {{ discount.value.name }}{{ discount.value.type ? " $" + discount.value.value : discount.value.value + "%" }}
                </button>
              </div>
            </div>
            <div v-if="errors">
              <div class="errors" v-for="(error, index) in errors" :key="index">
                {{ error }}
              </div>
            </div>

            <div v-if="paymentProccessing" class="svg-spinner">
              <LoadingSpinner spinner-message="Processing Payment..." />
            </div>

            <div>
              <h4>Payments</h4>
              <div class="payments">
                <button
                  v-if="siteSettings && siteSettings.eftpos_pay && remaining > 0 && !eftposBusy"
                  class="button is-primary button-spacing"
                  @click.stop.prevent="sendToEftpos(splitTotal)">
                  <div>
                    EFTPOS
                    <span v-if="remaining > 0">{{ "$" + splitTotal.toFixed(2) }}</span>
                  </div>
                  <div class="pencil-button" @click.stop.prevent="(this.eftposShow = !this.eftposShow), (this.moneyShow = false), (this.accountShow = false)">
                    <font-awesome-icon icon="pencil" style="color: var(--text-colour)" />
                  </div>
                </button>
                <button
                  v-if="siteSettings && siteSettings.eftpos_pay && remaining > 0 && eftposBusy && !eftposSig"
                  class="button is-primary button-spacing"
                  @click.stop.prevent="cancelEftpos">
                  <div>Cancel EFTPOS</div>
                </button>
                <div v-if="siteSettings && siteSettings.eftpos_pay && remaining > 0 && eftposBusy && eftposSig" class="button is-primary button-spacing">
                  <div>Signature?</div>
                  <button @click.stop.prevent="declineSig">No</button>
                  <button @click.stop.prevent="acceptSig">Yes</button>
                </div>
                <button v-if="siteSettings && siteSettings.cash_pay && remaining > 0" class="button is-primary button-spacing" @click.stop.prevent="payCash(splitTotal)">
                  <div>
                    CASH

                    <span v-if="remaining > 0">{{ "$" + splitTotal.toFixed(2) }}</span>
                  </div>
                  <div class="pencil-button" @click.stop.prevent="(this.moneyShow = !this.moneyShow), (this.eftposShow = false), (this.accountShow = false)">
                    <font-awesome-icon icon="pencil" style="color: var(--text-colour)" />
                  </div>
                </button>
                <!-- send 20 dollards to the check fee -->

                <button v-show="products.length" v-if="remaining === 0 || remaining <= 0" class="button is-primary" @click="completeTransaction">Complete Transaction</button>
                <button v-if="siteSettings && siteSettings.eftpos_pay && remaining <= 0" class="button is-primary" @click="$store.dispatch('deleteEftposPayment')">
                  Cancel Last Payment
                </button>
                <button v-if="siteSettings && siteSettings.post_pay && remaining > 0" class="button is-primary" @click="payLater(splitTotal)">
                  PAY LATER
                  <span v-if="remaining > 0">{{ "$" + splitTotal.toFixed(2) }}</span>
                </button>
                <button
                  v-if="siteSettings && siteSettings.accounts && remaining > 0"
                  class="button is-primary"
                  @click.stop.prevent="(this.accountShow = !this.accountShow), (this.eftposShow = false), (this.moneyShow = false)">
                  Account
                  <span v-if="remaining > 0">{{ "$" + splitTotal.toFixed(2) }}</span>
                </button>
                <button
                  v-if="siteSettings && siteSettings.vouchers && remaining > 0"
                  class="button is-primary"
                  @click.stop.prevent="(this.voucherShow = !this.voucherShow), (this.eftposShow = false), (this.moneyShow = false)">
                  Voucher
                  <span v-if="remaining > 0">{{ "$" + splitTotal.toFixed(2) }}</span>
                </button>
                <button
                  v-if="siteSettings && siteSettings.centrapay && remaining > 0 && !eftposBusy && !this.sentToCentrapay"
                  class="button is-primary"
                  @click.stop.prevent="sendToCentrapay">
                  CENTRAPAY
                  <span v-if="remaining > 0">{{ "$" + splitTotal.toFixed(2) }}</span>
                </button>
                <button
                  v-if="siteSettings && siteSettings.centrapay && remaining > 0 && this.sentToCentrapay"
                  class="button is-primary button-spacing"
                  @click.stop.prevent="cancelCentrapay">
                  <div>Cancel CENTRAPAY</div>
                </button>
                <button v-if="siteSettings && siteSettings.refund_allowed && remaining <= 0" class="button is-primary" @click="refund(total * -1)">
                  REFUND<span v-if="remaining <= 0">{{ "$" + total.toFixed(2) }}</span>
                </button>
              </div>
            </div>

            <div class="no-money">
              <button class="button is-primary button-spacing" :disabled="remaining <= 0" @click="payCash(100.0)">$100.00</button>
              <button class="button is-primary" :disabled="remaining <= 0" @click="payCash(50.0)">$50.00</button>
              <button class="button is-primary" :disabled="remaining <= 0" @click="payCash(20.0)">$20.00</button>
              <button class="button is-primary" :disabled="remaining <= 0" @click="payCash(10.0)">$10.00</button>
              <button class="button is-primary" :disabled="remaining <= 0" @click="payCash(5.0)">$5.00</button>
              <button class="button is-primary" :disabled="remaining <= 0" @click="payCash(2.0)">$2.00</button>
              <button class="button is-primary" :disabled="remaining <= 0" @click="payCash(1.0)">$1.00</button>
              {{ changeDue }}
            </div>
            <h4 v-if="moneyShow">Custom Amount</h4>
            <div :class="moneyShow ? 'money' : 'no-money'">
              <div>
                <input class="input" type="number" name="customAmount" min="1" :disabled="!moneyShow" v-model="customAmount" placeholder="Enter Amount" />
              </div>
              <select id="showmethemoney" name="dropdown" class="dollarBills" v-model="customAmount" :disabled="!moneyShow">
                <option value="100">$100</option>
                <option value="50">$50</option>
                <option value="20" selected>$20</option>
                <option value="10">$10</option>
                <option value="5">$5</option>
              </select>
              <button class="button is-primary" :disabled="remaining <= 0" @click="customAmount > 0 ? payCash(customAmount) : null">CASH {{ "$" + customAmount }}</button>
            </div>
            <h4 v-if="eftposShow">Custom Amount</h4>
            <div :class="eftposShow ? 'eftpos' : 'no-eftpos'">
              <div>
                <input class="input" type="number" name="customAmount" :disabled="!eftposShow" min="1" v-model="customAmount" placeholder="Enter Amount" />
              </div>
              <button class="button is-primary" :disabled="remaining <= 0" @click="customAmount > 0 ? sendToEftpos(customAmount) : null">
                <div>EFTPOS {{ "$" + customAmount }}</div>
              </button>
            </div>
            <h4 v-if="accountShow">Add to Account</h4>
            <div :class="accountShow ? 'account' : 'no-account'">
              <form role="search" class="search">
                <input id="search" type="search" placeholder="Search..." autofocus required v-model="searchQuery" />
                <!-- <button @click="search()" type="submit">Go</button> -->
              </form>
              <ul v-if="searches.length" :class="'grid wrapper layout content-gap'">
                <template v-bind:key="search" v-for="search in searches">
                  <li @click="sendToAccount(search.key[0], search.key[1], remaining)" class="modifierBox">
                    <div class="box">
                      <p>
                        <span class="modifier-name">Name: {{ search.key[0] }}</span>
                        <span class="modifier-name">ID: {{ search.key[1] }}</span>
                      </p>
                      <span></span>
                    </div>
                  </li>
                </template>
              </ul>
            </div>
            <h4 v-if="voucherShow">Debit Voucher</h4>
            <div :class="voucherShow ? 'account' : 'no-account'">
              <form @submit.stop.prevent role="search" class="search">
                <input id="search" type="search" placeholder="Search by voucher ID..." autofocus required v-model="voucherSearchQuery" />
              </form>
              <ul v-if="searches.length" :class="'grid wrapper layout content-gap'">
                <template v-bind:key="search" v-for="search in searches">
                  <li @click="sendToVoucher(search.key[0], search.key[1], remaining, search.value)" class="modifierBox">
                    <div class="box">
                      <p>
                        <span class="modifier-name">Name: {{ search.key[0] }}</span>
                        <span class="modifier-name">ID: {{ search.key[1] }}</span>
                        <span class="modifier-name">Value: ${{ search.value.toFixed(2) }}</span>
                      </p>
                      <span></span>
                    </div>
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </form>
        <Cart />
      </div>
    </div>
  </teleport>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
//import {bagelSocket} from "@/helpers/socket";
// import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { ref } from "vue";
// import parsePhoneNumber, { validatePhoneNumberLength } from "libphonenumber-js";
import { api } from "@/helpers/api";
import LoadingSpinner from "@/components/_layouts/LoadingSpinner";
import Cart from "./Cart.vue";

export default {
  name: "CheckoutModal",
  props: ["open", "close"],
  emits: ["showWebOrderModal"],
  components: { LoadingSpinner, Cart },
  data() {
    return {
      stripe: null,
      elements: null,
      intentLoaded: false,
      paymentProccessing: false,
      year: new Date().getFullYear(),
      dates: ["2023/12/25", "2023/12/26", "2024/1/1", "2024/1/2"],
      openModal: false,
      openSplitEftpos: false,
      openDiscountModal: false,
      openNotesModal: false,
      showLog: false,
      name: null,
      notes: null,
      email: null,
      email_receipt: false,
      cell: "",
      location: "",
      shops: JSON.parse(window.localStorage.getItem("shops")),
      errors: [],
      stripeErrors: [],
      delivery: "",
      when: null,
      splitOrderCurrentTotal: null,
      paymentStatus: "pending", // pending -> partial -> full -> post -> complete (orders can be appending to, e.g. dessert),
      moneyShow: false,
      eftposShow: false,
      accountShow: false,
      voucherShow: false,
      customAmount: 0,
      changeDue: 0,
      searches: [],
      searchQuery: "",
      voucherSearchQuery: "",
      allergens: false,
      eftposBusy: false,
      eftposSig: false,
      sentToEftpos: 0,
      sentToCentrapay: false,
    };
  },
  setup() {
    const date = ref(new Date(new Date().setDate(new Date().getDate() + 2)));
    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `Selected date is ${day}/${month}/${year}`;
    };
    const endDate = () => {
      return new Date("25/12/2022");
    };

    const handleDate = (modelData) => {
      date.value = modelData;
      //console.log(date);
      //date.value = intlDateObj.format(date.value);
      // do something else with the data
    };
    return {
      date,
      format,
      endDate,
      handleDate,
    };
  },

  created() {
    if (window.localStorage.getItem("futurePickup")) {
      this.$store.dispatch("setFuturePickup", true);
    }

    //bagelSocket.setUpChannel(this);
    //this.channel = this.$.appContext.config.globalProperties.channel;
  },
  beforeMount() {
    window["Beamy"] = {
      components: this,
      eftposMSG: (msg) => this.eftposHandler(msg),
      eftposSignature: () => this.eftposSigHandler(),
    };
  },
  async mounted() {
    // const stripeKey = process.env.VUE_APP_STRIPE_LIVE;
    // // estlint-disable-next-line
    // this.stripe = window.Stripe(stripeKey);
    // this.stripe.api_version = "2024-10-28.acacia";
  },
  methods: {
    ...mapActions(["addToCart", "increaseCartQuantity", "decreaseCartQuantity", "addPayment", "clearCart", "clearPayments"]),
    async search() {
      if (this.searchQuery.length > 1) {
        const search = await api.searchAccounts(this.searchQuery);
        let result = search.rows.map((acc) => (acc.key[2] == "id-name" ? { key: [acc.key[1], acc.key[0]] } : acc));
        //console.log(result);
        this.searches = result;
      } else {
        this.searches = [];
      }
    },
    async voucherSearch() {
      if (this.voucherSearchQuery.length > 1) {
        const search = await api.searchVouchers(this.voucherSearchQuery);
        console.log(search);
        let result = search.rows.map((acc) => (acc.key[2] == "id-name" ? { key: [acc.key[1], acc.key[0]], value: acc.value } : acc));
        console.log(result);
        this.searches = result;
      } else {
        this.searches = [];
      }
    },
    selectDelivery(option) {
      //console.log(option, this.cartOrder._id);
      if (option && (document.querySelectorAll('input[type="radio"]') != null)) {
        document.querySelectorAll('input[type="radio"]').checked = false;
        document.querySelector("." + option).checked = !document.querySelector("." + option).checked;
        this.delivery = option;
      }
    },
    activeDiscounts() {
      return this.discounts.filter((discount) => (discount.value.active ? discount : null));
    },
    applyDiscount(discount) {
      //console.log(discount);
      let value = 0;
      if (discount.value.type === true) {
        value = discount.value.value;
      } else {
        value = (this.total / 100) * discount.value.value;
      }
      /* only apply the discount once */
      let hasDiscount = this.payments.find((payment) => {
        return payment.type === "discount";
      });
      if (!hasDiscount) {
        this.addPayment({ type: "discount", value: value });
      }
    },
    payCash(value) {
      this.splitOrderCurrentTotal = 0;
      //console.log(value, this.remaining);
      if (parseFloat(value) > parseFloat(this.remaining)) {
        this.changeDue = value - this.remaining;
        this.addPayment({ type: "cash", value: this.remaining });
        this.$notify({
          text: `Change Due: $${this.changeDue.toFixed(2)}`,
          type: "success",
          duration: -1,
        });
      } else {
        this.addPayment({ type: "cash", value: value });
      }
      if (this.remaining == 0) {
        this.completeTransaction();
      }
    },
    refund(value) {
      this.splitOrderCurrentTotal = 0;
      this.addPayment({ type: "refund", value: value });
    },
    splitOrder(hash) {
      let foundLineItem = this.products.find((p) => {
        return p.hash === hash;
      });
      if (foundLineItem) {
        this.splitOrderCurrentTotal += foundLineItem.price * foundLineItem.quantity;
      } else {
        //console.log("No line item found");
      }
    },
    sendToAccount(name, id, value) {
      let currAcc = {
        ID: id,
        name: name,
        doc_type: "debit",
        value: value,
        date: new Date().toISOString(),
      };
      this.channel.push("account:doc", currAcc);
      this.splitOrderCurrentTotal = 0;
      this.addPayment({ type: "account", value: value, id: id, name: name });
    },
    sendToVoucher(name, id, value, voucherBalance) {
      if (voucherBalance <= 0) {
        this.$notify({
          text: `Voucher had no remaining credit`,
          type: "warn",
          duration: 5000,
        });
        return;
      }
      if (this.payments.find((payment) => payment.id === id)) {
        this.$notify({
          text: `Voucher already used`,
          type: "warn",
          duration: 5000,
        });
        return;
      }

      let newValue = value > voucherBalance ? voucherBalance : value;
      let currAcc = {
        ID: id,
        name: name,
        doc_type: "debit",
        value: newValue,
        date: new Date().toISOString(),
      };
      this.channel.push("voucher:doc", currAcc);
      this.voucherUsed = true;
      this.splitOrderCurrentTotal = 0;
      this.addPayment({ type: "voucher", value: newValue, id: id, name: name });
      this.voucherShow = false;
    },
    sendToCentrapay() {
      this.channel.push("centrapay:init_payment", { products: this.products });
      this.sentToCentrapay = !this.sentToCentrapay;
    },
    cancelCentrapay(){
      this.channel.push("centrapay:cancel_payment")
      this.sentToCentrapay = false;
    },
    sendToEftpos(value) {
      this.sentToEftpos = parseFloat(value).toFixed(2);
      /* TODO: Fire callback to App wrapper to send to EFTPOS machine */
      if (window.Android && window.Android.chargeEFTPOS) {
        window.Android.chargeEFTPOS(parseFloat(value).toFixed(2).toString(), this.eftposSettings[0].ip, parseInt(this.eftposSettings[0].port));
        this.eftposBusy = true;
      } else {
        this.addPayment({ type: "eftpos", value: value});
      }
      this.splitOrderCurrentTotal = 0;
    },
    acceptSig() {
      this.eftposSig = false;
      window.Android.acceptSig(true);
    },
    declineSig() {
      this.eftposSig = false;
      window.Android.acceptSig(false);
    },
    eftposSigHandler() {
      this.eftposSig = true;
    },
    checkEftposFee(msg) {
      if (parseFloat(msg) != this.sentToEftpos) {
        // if the money payed is not the same amount as what was sent to eftpos machine
        let feePrice = parseFloat(msg) - this.sentToEftpos;
        var fee = { id: "product:fee", modifiers: {}, price: feePrice};
        try {
          // add the fee to the cart as a product with an id, modifiers, price and hash
          this.addToCart(fee);
        } catch (error) {
          console.log(error);
        }
      }
    },
    eftposHandler(message) {
      this.splitOrderCurrentTotal = 0;
      this.eftposBusy = false;
      let msg = JSON.parse(message);
      if (msg[0] == "APPROVED") {
        this.checkEftposFee(msg[1]);
        this.addPayment({ type: "eftpos", value: parseFloat(msg[1]) });
        this.$notify({
          text: "EFTPOS: Approved",
          type: "success",
          duration: 5000,
        });
      } else if (msg[0] == "SIGNATURE APPROVED") {
        this.checkEftposFee(msg[1]);
        this.addPayment({ type: "eftpos", value: parseFloat(msg[1]) });
        this.$notify({
          text: "EFTPOS: Signature Approved",
          type: "success",
          duration: 5000,
        });
      } else if (msg[0] == "ERROR") {
        this.$notify({
          text: "EFTPOS: Terminal busy, please try again",
          type: "error",
          duration: 5000,
        });
      } else {
        this.$notify({
          text: "EFTPOS: " + msg[0],
          type: "error",
          duration: -1,
        });
      }
      //console.log(msg);
      let companion = { products: this.products, payment: msg[1], payment_message: msg[0] };
      this.channel.push("cart:state", companion);
      console.log("remaining:", this.remaining);
      if (this.remaining == 0 && !this.siteSettings.nameRequired) {
        this.completeTransaction();
      }
    },
    cancelEftpos() {
      if (window.Android && window.Android.cancelEFTPOS) {
        window.Android.cancelEFTPOS(this.eftposSettings[0].ip, parseInt(this.eftposSettings[0].port));
        this.eftposBusy = false;
      }
    },
    payLater() {
      //console.log("Pay Later (post pay): ", value);
      this.completeTransaction();
    },
    sendToEftposSplit(value) {
      //console.log("Send to eftpos via split: ", value);
      this.addPayment({ type: "eftpos", value: value });
    },
    giveChange(value) {
      this.addPayment({ type: "change", value: value });
    },
    checkRequirements() {},
    transactionValidation() {
      //console.log('errors')
      this.errors = [];

      if (this.siteSettings.nameRequired && !this.name) {
        this.errors.push("Name is required");
      }
      if (!this.delivery && this.siteSettings.delivery_options.length !== 0) {
        if (this.siteSettings.delivery_options.length == 1) {
          this.delivery = this.siteSettings.delivery_options[0];
        } else {
          this.errors.push("Delivery is required");
        }
      }
      console.log("transaction validation ", this.siteSettings);
      //console.log(this.delivery);
    },
    completeTransaction() {
      // reply = false, ??
      this.transactionValidation();
      this.eftposBusy = false;
      if (this.errors.length === 0) {
        this.openModal = false;
        //console.log("Ready to complete transaction");
        //console.log(
        //   "On callback from complete transaction - need to clear the payments and cart"
        // );

        this.channel = this.$.appContext.config.globalProperties.channel;

        let delivery = this.delivery;
        if (delivery == "online" && this.futurePickup) {
          delivery = "future-pickup";
        }
        var now = new Date();
        now.toISOString();

        var order_data = {
          version: 2,
          name: this.name,
          notes: this.notes,
          location: this.location,
          complete: false,
          paymentStatus: this.remaining === 0 ? "paid" : "partial",
          delivery: delivery,
          products: this.products,
          payments: this.payments,
          pickup_date: this.date.toLocaleDateString("sv-SE"),
          total: this.total,
          events: {},
        };

        console.log("completeTransaction ", order_data);

        // a different listener can respond to the invoice */

        //n//console.log(this.cartOrder);
        /* send to web socket */
        if (this.cartOrder && this.cartOrder._id) {
          console.log("cartOrder", this.cartOrder);
          order_data["events"]["created_at"] = this.cartOrder["created_at"];
          order_data["events"]["updated_at"] = now.toISOString();
          order_data["_id"] = this.cartOrder._id;
          // this.cartOrder['events']['updated_at'] = now.toISOString()
          //let doc = { ...this.cartOrder, ...order_data };
          ////console.log(doc);
          this.channel.push("order:update", order_data);
        } else {
          order_data["events"] = { created_at: now.toISOString(), updated_at: now.toISOString() };

          this.channel.push("order:new", order_data).receive("ok", async (reply) => {
            console.log("reply", reply);
            let orderUser = { ...this.user };
            !orderUser["orders"] ? (orderUser["orders"] = [reply.reply.rows[0].id]) : orderUser["orders"].push(reply.reply.rows[0].id);
          });
        }
        this.clearCart();
        this.clearPayments();
        this.splitOrderCurrentTotal = 0;
        this.voucherShow = false;
        this.voucherSearchQuery = "";
        this.paymentProccessing = false;
      }
    },
  },

  computed: {
    ...mapGetters({
      siteSettings: "siteSettings",
      payments: "cartPayments",
      products: "cartProducts",
      discounts: "discounts",
      cartOrder: "cartOrder",
      eftposSettings: "eftposSettings",
      orderName: "orderName",
      orderNotes: "orderNotes",
      orderDelivery: "orderDelivery",
      futurePickup: "futurePickup",
      user: "user",
    }),
    surchargeTotal() {
      const total = this.products.reduce((total, p) => {
        return total + p.price * p.quantity;
      }, 0);
      return 0.15 * total;
    },
    ccTotal() {
      const total = this.products.reduce((total, p) => {
        return total + p.price * p.quantity;
      }, 0);
      return 0.03 * total;
    },
    total() {
      let total = this.products.reduce((total, p) => {
        return total + p.price * p.quantity;
      }, 0);
      if (this.siteSettings.surcharge) {
        total += this.surchargeTotal;
      }
      return total;
    },
    paid() {
      return this.payments.reduce((total, p) => {
        return total + p.value;
      }, 0);
    },
    remaining() {
      return this.total - this.paid;
    },
    /* split total is either the remaining total or the current total for items that have been split but not yet paid for */
    splitTotal() {
      if (this.splitOrderCurrentTotal && this.splitOrderCurrentTotal > 0) {
        return this.splitOrderCurrentTotal;
      }
      return this.remaining;
    },
    physicalShops() {
      let shops = {};
      for (let key in this.shops) {
        if (this.shops[key].online) {
          shops[key] = this.shops[key];
        }
      }
      return shops;
    },
  },
  watch: {
    searchQuery: function () {
      this.search();
    },
    voucherSearchQuery: function () {
      this.voucherSearch();
    },
    async open() {
      this.openModal = true;
      this.customAmount = 0;
      this.moneyShow = false;
      this.eftposShow = false;
      this.openNotesModal = true;
      this.name = this.orderName != null && this.orderName.length > 0 ? this.orderName : null;
      this.notes = "";
      this.delivery = this.orderDelivery;
      this.sentToCentrapay = false; // is this right for reseting centrapay?
      if (this.delivery != null) {
        this.selectDelivery(this.delivery);
      }
    },
    openModal() {
      if (!this.openModal) {
        this.intentLoaded = false;
      }
    },
    close() {
      this.openModal = false;
      this.intentLoaded = false;
    },
    /***orderName() {
      this.name = this.orderName;
    },
    orderNotes() {
      this.notes = this.orderNotes;
    },
    orderDelivery() {
      this.delivery = this.orderDelivery;
    },***/
  },
};
</script>
<style scoped lang="scss">
@import "public/layout";
@import "public/actions";
@import "public/wrapper";
@import "public/toggle";
@import "public/search";

.remaining {
  display: flex;

  margin-top: 0px;
  border-bottom: 2px solid #ccc;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  text-align: right;

  span.label {
    flex: 0 1 auto;
    display: inline-block;
    text-align: left;
  }

  span.value {
    flex: 1 0;
    display: inline-block;
  }
}

.actions {
  margin-top: 1rem;
}
.log {
  list-style: none;
  text-transform: capitalize;
  padding: 0px;
  text-align: right;
}

.modal {
  .exit {
    font-size: 2rem;
    text-align: center;
    display: block;
    margin: auto;
    cursor: pointer;
    color: var(--action-colour);
  }
  .time {
    color: var(--action-colour);
    font-size: 1.5rem;
    text-align: center;
    display: block;
    margin: 0.5rem auto;
  }
  // .StripeElement {
  //   padding: 1rem;
  // }

  div.outer {
    display: flex;
    background-color: var(--secondary-colour);
    min-width: 50vw;
    max-width: 90vw;
    min-height: 50vh;
    max-height: 90vh;
    padding: 1rem;
    justify-content: space-around;
    align-items: baseline;
    border-radius: 0.5rem;
    flex-direction: row;
    flex-wrap: wrap;
    .bump-right {
      flex: 1 0 100%;
      // left: 50%;
    }
    form {
      display: contents;
    }

    h2 {
      padding-top: 0px;
    }

    div.details {
      flex: 1 1;
      padding: 1rem;
      overflow-y: auto;

      .logged-in {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 10px;
        .logged-in-user {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin: 0.5rem 0;
        }
        .user-info {
          margin-left: 1rem;
          font-size: x-large;
        }

        .notes {
          input {
            background-color: var(--primary-colour);
            color: var(--primary-colour);
          }
          .allergies {
            label {
              margin: 0;
              font-size: x-large;
              h4 {
                text-wrap: nowrap;
              }
            }
          }
          div {
            textarea {
              // padding: 0.5rem;
              margin-left: 1rem;
              font-size: small;
              width: 15rem;
              &::placeholder {
                font-size: small;
              }
            }
          }
        }
        div {
          button {
            width: 40rem;
          }
        }
      }

      .svg-spinner {
        background-color: rgba(255, 255, 255, 0.5);
        z-index: 10;
        position: absolute;
        height: 100vh;
        width: 100vw;
        top: 0;
        left: 0;
        .loading-spinner {
          z-index: 100;
          position: absolute;
        }
      }

      h4 {
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
      }

      .notes,
      .email,
      .cell,
      .eftpos,
      .money,
      .location {
        input,
        select {
          font-family: "Lato";
          margin-bottom: 1rem;
          border: 1px solid var(--boder-colour);
          width: calc(100% - 2rem);
          padding: 1rem 1rem 1rem 1rem;
          font-size: 1.5rem;
          height: 2rem;
          border-radius: 0.5rem;
        }
        textarea {
          font-family: "Lato";
          margin-bottom: 1rem;
          border: 1px solid var(--border-colour);
          width: calc(100% - 2rem);
          padding: 1rem 1rem 1rem 1rem;
          font-size: 1.5rem;
          height: 2rem;
          resize: none;
          border-radius: 0.5rem;
        }
        button {
          margin: auto;
          margin-top: 0.5rem;
        }
        .allergies {
          display: flex;
          flex-direction: row;
          font-size: 1.5rem;
          justify-content: flex-start;
          align-items: flex-start;
          font-size: 1.25rem;
        }
      }

      .notes {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
        grid-auto-flow: row;
        grid-column-gap: 1rem;
        .allergies {
          text-wrap: nowrap;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          input {
            width: 2rem;
            margin: 0.5rem;
          }
          textarea {
            padding: 0.5rem;
            margin-left: 1rem;
            width: 14rem;
          }
        }
      }

      .delivery {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        grid-auto-flow: row;
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;

        input {
          appearance: none;

          border-radius: 50%;
          width: 1rem;
          height: 1rem;
          border: 2px solid var(--action-colour);
          margin-right: 0.5rem;
        }
        input:checked {
          background-color: var(--action-colour);
          // border: 0.6rem solid var(--action-colour);
        }
        span {
          text-transform: capitalize;
          display: inline-block;
          padding: 1rem;
          border: 1px solid #ccc !important;
          background-color: var(--primary-colour);
          font-size: 1.4rem;
          border-radius: 0.5rem;
        }
      }

      .discounts {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        grid-auto-flow: row;
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;

        button {
          margin: 0px;
        }
        button:first-child {
          flex: 0.5 1;
          margin-right: 1rem;
        }
        button:nth-child(1) {
          flex: 0.5 1;
        }
        button:last-child {
          flex: 0.5 1;
          margin-right: 0px;
        }
      }

      .payments {
        display: flex;
        gap: 5px;

        .button-spacing {
          display: flex !important;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }
        .pencil-button {
          background-color: var(--secondary-colour);
          border-radius: 0.5rem;
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        button {
          margin: 0px;
        }
      }

      .stripe {
        button {
          margin: 10px 0px;
        }
        margin-top: 1rem;
      }
    }
    .errors {
      color: var(--warning-colour);
      margin: 1rem;
      font-size: 1.5rem;
    }

    div.cart {
      flex: 0 1;
      padding: 0.5rem 0.5rem 0.5rem 0.5rem;
      min-width: 250px;
    }
  }
}

@media only screen and (max-width: 995px) {
  .cust-info {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)) !important;
  }
}

@media screen and (orientation: landscape) {
  .outer {
    flex-direction: row;
  }
}

@media screen and (orientation: portrait) {
  .modal {
    .outer {
      flex-direction: column-reverse;
    }

    & > div {
      display: flex;
      flex-direction: column;
      background-color: var(--primary-colour);
      width: 100%;
      padding: 1rem;
      overflow-y: scroll;
    }

    div.outer {
      form {
        display: block;
        margin: 0;
        div.details {
          flex: 0;
          .logged-in {
            .logged-in-user {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
              gap: 10px;
            }
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 10px;
            div {
              button {
                width: 20rem;
                margin-left: 0;
              }
            }
            .notes {
              width: 100%;
            }
          }

          .notes,
          .email,
          .cell,
          .location {
            input,
            select {
              font-size: 1rem;
            }
            textarea {
              font-size: 1rem;
            }

            .notes {
              display: grid;
              grid-template-areas:
                "cart"
                "cust-info";
            }
            grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
          }
        }
      }
      div.cart {
        min-width: inherit;
      }
      .payments {
        display: grid !important;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      }
    }
  }
}

h2 {
  margin-top: 0px;
  padding-top: 1rem;
}

button {
  padding: 1rem;
  width: 100%;
  font-size: 1.5rem;

  &.is-primary {
    margin-bottom: 1rem;
  }
}

#showmethemoney {
  height: 80%;
  width: 100%;
  text-align: center;
}

.money,
.eftpos {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-auto-flow: row;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  flex-wrap: wrap;
  justify-content: stretch;

  select.dollarBills {
    height: 80%;
    width: 100%;
  }

  button {
    margin-left: 0px;
  }
}

.no-money,
.no-eftpos,
.no-account {
  display: none;
}
</style>
